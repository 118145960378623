
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'

export default defineComponent({
  components: { TmFormLine, TmButton, TmModal },
  setup() {
    const email = ref('thuhang.nute@gmail.com')
    const radios = ref(['replies', 'history', 'html'])
    return {
      email,
      radios,
    }
  },
})
